import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AppDialog from "../common/AppDialog/AppDialog";

function LostReasonDialog({ open, onClose, remarks }) {
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title="Lost Reason"
      maxWidth="xs"
      minHeight="auto"
    >
      <Box my={2}>
        <Typography variant="body1" paragraph>
          {remarks}
        </Typography>
      </Box>
    </AppDialog>
  );
}

export default LostReasonDialog;

LostReasonDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  updateStatus: PropTypes.func,
  setremarks: PropTypes.func,
  remarks: PropTypes.string, // Updated type to string for clarity
  loading: PropTypes.bool,
};
