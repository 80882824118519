import {
  Box,
  IconButton,
  Tooltip,
  Button,
  gridClasses,
  // Radio,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import {
  fetchUnderReviewOpportunity,
  // fetchAllPendingOpportunity,
  fetchOpportunityDocumentsData,
  fetchOpportunityByIdData,
} from "../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import ArticleIcon from "@mui/icons-material/Article";
import DocumentsDialog from "../../components/DocumentsDialog/DocumentsDialog";

// import GCsDialog from "../../components/CreateJob/GCsDialog/GCsDialog";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import MarkOpenDialog from "../../components/ProposalReview/MarkOpenDialog/MarkOpenDialog";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import NoteViewDialog from "../../components/AddNoteDialog/NoteViewDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function Proposal_Review() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  // const [selectedRowId, setSelectedRowId] = useState(null);
  const [documentApiCalling, setDocumentApiCalling] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [opportunityId, setopportunityId] = useState(null);
  // const [isEstimationAdded, setIsEstimationAdded] = useState(false);
  const [estimationApiLoading, setEstimationApiLoading] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [checkedRowsData, setCheckedRowsData] = useState([]);
  const [showMarkOpenDialog, setShowMarkOpenDialog] = useState(false);
  const [apiCalling, setApiCalling] = useState(false);
  const [cachedRows, setCachedRows] = useState({});
  const [filter] = useState("Default");
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 100,
  });

  // const { setsnackBarData } = useContext(SnackBarContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);
  const {
    OpportunityOpenData,
    status,
    OpportunityDocumentsData,
    ProposalReviewByIdData,
    TotalCount,
    OpportunityByIdData,
  } = useSelector((state) => state?.OpportunitySettings);

  useEffect(() => {
    // dispatch(fetchUnderReviewOpportunity());
    dispatch(
      fetchUnderReviewOpportunity({
        status: "Under Review",
        page: pageState.page,
        pageSize: pageState.pageSize,
        sort: filter,
      })
    );
  }, [dispatch, filter]);

  useEffect(() => {
    if (OpportunityOpenData) {
      setalldata(OpportunityOpenData);
    } else {
      setalldata([]);
    }
  }, [OpportunityOpenData]);

  useEffect(() => {
    if (apiCalling && OpportunityByIdData) {
      setApiCalling(false);
      navigate(`details`, {
        state: OpportunityByIdData,
      });
    } else {
      setApiCalling(false);
    }
  }, [OpportunityByIdData]);

  // const handleChangeStatus = () => {
  //   navigate("/proposal_review/view", {
  //     state: { selectedRowId },
  //   });
  //   handleBreadcrumbs("Proposal View", "/proposal_review/view");
  //   // setopportunityId(id);
  // };

  const handleBreadcrumbs = (name, url) => {
    setBreadcrumbsItems([
      {
        id: breadcrumbsItems.length,
        url: "/",
        name: "Dashboard",
      },
      {
        id: breadcrumbsItems.length,
        url: "/proposal_review",
        name: "Proposal Review",
      },
      {
        id: breadcrumbsItems.length,
        url: url,
        name: name,
      },
    ]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            width: "100%",
          }}
        >
          {checkedRows?.length > 0 ? (
            <Box sx={{ display: "flex", gap: "5px" }}>
              {/* <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  if (isEstimationAdded) {
                    dispatch(fetchProposalReviewId(selectedRowId));
                    setEstimationApiLoading(true);
                  } else {
                    handleChangeStatus();
                  }
                }}
              >
                {isEstimationAdded ? "View Proposal" : "Add Proposal"}
              </Button> */}

              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  setShowMarkOpenDialog(true);
                }}
              >
                Mark Open
              </Button>
            </Box>
          ) : (
            <Box>
              <GridToolbarExport />
              <GridToolbarFilterButton />
            </Box>
          )}
        </Box>
      </GridToolbarContainer>
    );
  }

  // const handleCheckboxChange = (rowData) => {
  //   setSelectedRowId(rowData?.opportunityId);
  //   setIsEstimationAdded(rowData?.isEstimationAdded);
  // };

  const columns = [
    {
      field: "sr",
      headerName: "Sr",
      minWidth: 30,
      maxWidth: 30,
      flex: "auto",
      renderCell: (params) => {
        return (pageState.page - 1) * pageState.pageSize + params.value;
      },
    },
    // {
    //   field: "select",
    //   headerName: "Select",
    //   minWidth: 50,
    //   flex: "auto",
    //   renderCell: (params) => (
    //     <Radio
    //       checked={selectedRowId === params.row.opportunityId}
    //       onChange={() => handleCheckboxChange(params.row?.rowData)}
    //     />
    //   ),
    // },

    {
      field: "jobNo",
      headerName: "Job #",
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      cellClassName: (params) => {
        switch (params.value) {
          case "On Hold":
            return "status-onHold";
          case "Pending":
            return "status-pending";
          case "In Progress":
            return "status-inProgress";
          case "Open":
            return "status-open";
          case "Lost":
            return "status-lost";
          case "Sold":
            return "status-sold";
          case "No Opportunity":
            return "status-noOpportunity";
          case "Under Review":
            return "status-underReview";
          default:
            return "";
        }
      },
    },
    // {
    //   field: "opportunityValue",
    //   headerName: "Amount",
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: (params) =>
    //     `${params?.row?.opportunityValue?.toLocaleString()}`,
    // },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/companies/form", {
                state: params?.row?.rowData?.company,
              });
            }}
          >
            {params?.row?.company}
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "clientDueDate",
      headerName: "Client Due Date",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => {
        const dateValue = params.value;
        return dateValue ? moment(dateValue, "MM/DD/YYYY").toISOString() : null;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        return dateValue
          ? moment(new Date(dateValue), "YYYY-MM-DDTHH:mm:ss").format(
              "MM/DD/YYYY"
            )
          : "N/A";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 70,
      flex: "auto",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Documents">
              <IconButton
                sx={{ padding: "5px" }}
                color="secondary"
                onClick={() => {
                  setDocumentApiCalling(true);
                  setSelectedRow(params?.row?.rowData);
                  dispatch(
                    fetchOpportunityDocumentsData({
                      opportunityId: params?.row?.rowData?.opportunityId,
                      refName: null,
                      refId: null,
                    })
                  );
                }}
              >
                <ArticleIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Notes">
              <IconButton
                sx={{ padding: "5px" }}
                color="info"
                onClick={() => {
                  setShowNotes(true);
                  setSelectedRow(params?.row?.rowData);
                }}
              >
                <DescriptionOutlinedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Detail">
              <IconButton
                sx={{ padding: "5px" }}
                color="success"
                onClick={() => {
                  setApiCalling(true);
                  dispatch(
                    fetchOpportunityByIdData(
                      params?.row?.rowData?.opportunityId
                    )
                  );
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: item?.opportunityId,
          sr: idx + 1,
          title: item?.title,
          opportunityId: item?.opportunityId,
          description: item?.description,
          source: item?.opportunitySource?.title,
          contact: item?.contact?.display_name,
          company: item?.company?.name,
          status: item.status,
          type: item?.type?.title,
          clientDueDate: moment(item?.clientDueDate).format("MM/DD/YYYY"),
          createdAt: moment(item?.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(item?.updatedAt).format("MM/DD/YYYY"),
          rowData: item,
          opportunityValue: item?.opportunityValue
            ? `$${item?.opportunityValue}`
            : "N/A",
          jobNo: item?.jobNo,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (documentApiCalling && OpportunityDocumentsData) {
      setDocumentApiCalling(false);
      setShowDocumentDialog(true);
    } else {
      setDocumentApiCalling(false);
    }
  }, [OpportunityDocumentsData]);

  useEffect(() => {
    if (estimationApiLoading) {
      if (ProposalReviewByIdData) {
        navigate("/proposal_review/view", {
          // state: {
          //   selectedRowId,
          //   estimationData: ProposalReviewByIdData,
          // },
        });
        handleBreadcrumbs("Proposal View", "/proposal_review/view");
      }
      setEstimationApiLoading(false);
    }
  }, [ProposalReviewByIdData]);

  useEffect(() => {
    let arr = alldata?.filter((item) =>
      checkedRows?.includes(item?.opportunityId)
    );
    setCheckedRowsData(arr);
  }, [checkedRows]);

  useEffect(() => {
    const fetchData = () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      if (cachedRows[pageState.page]) {
        setPageState((old) => ({ ...old, isLoading: false }));
        return;
      } else {
        try {
          dispatch(
            fetchUnderReviewOpportunity({
              status: "Under Review",
              page: pageState.page,
              pageSize: pageState.pageSize,
              sort: filter,
            })
          );
        } catch (error) {
          console.error("Failed to fetch opportunities", error);
        } finally {
          setPageState((old) => ({ ...old, isLoading: false }));
        }
      }
    };
    fetchData();
  }, [pageState.page, pageState.pageSize, filter]);

  useEffect(() => {
    setCachedRows((prev) => ({
      ...prev,
      [pageState.page]: rows,
    }));
  }, [rows]);

  const rows1 = cachedRows[pageState.page] || [];

  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows1}
          pagination
          paginationMode="server"
          columns={columns}
          checkboxSelection
          rowSelectionModel={checkedRows}
          onRowSelectionModelChange={(ids) => {
            setCheckedRows(ids);
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageState.pageSize,
                page: pageState.page - 1,
              },
            },
          }}
          onPaginationModelChange={(model) => {
            console.log("model", model);
            setPageState((old) => ({ ...old, page: model.page + 1 }));
          }}
          pageSizeOptions={false}
          rowCount={TotalCount}
          loading={pageState.isLoading}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />

        {status === "loading" ? <Loader /> : null}
      </Box>

      <DocumentsDialog
        open={showDocumentDialog}
        onClose={() => {
          setSelectedRow(null);
          setShowDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />

      {/* <GCsDialog
        open={opportunityId ? true : false}
        onClose={(success) => {
          if (success) {
            dispatch(fetchAllPendingOpportunity("Pending"));
          }
          setopportunityId(null);
          setSelectedRowId(null);
        }}
        opportunityId={selectedRowId}
      /> */}
      {showNotes && (
        <NoteViewDialog
          open={showNotes}
          onClose={() => {
            setShowNotes(false);
            setSelectedRow(null);
          }}
          state={selectedRow}
        />
      )}
      <MarkOpenDialog
        open={showMarkOpenDialog}
        onClose={() => {
          setCheckedRows([]);
          setShowMarkOpenDialog(false);
        }}
        checkedRowsData={checkedRowsData}
      />
    </>
  );
}

export default Proposal_Review;
