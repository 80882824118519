import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import LineChart from "./charts/LineChart";
import {
  fetchOpportunityRevenueSummaryData,
  fetchOpportunitySuccessRateData,
} from "../../../redux/slice/DashboardSlice/DashboardSlice";
import moment from "moment/moment";
import PieChart from "./charts/PieChart";
import { Button, TextField } from "@mui/material";

function StatCharts() {
  const classes = useStyles();
  const [revenueFields, setRevenueFields] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [pieChartData, setPieChartData] = useState(null);

  const dispatch = useDispatch();
  const { OpportunityRevenueSummaryData, OpportunitySuccessRateData } =
    useSelector((state) => state.Dashboard);

  useEffect(() => {
    dispatch(fetchOpportunityRevenueSummaryData());
  }, []);

  useEffect(() => {
    callOpportunitySuccessApi();
  }, []);

  useEffect(() => {
    if (OpportunityRevenueSummaryData) {
      setRevenueFields(Object.keys(OpportunityRevenueSummaryData).reverse());
      setRevenueData(Object.values(OpportunityRevenueSummaryData).reverse());
    }
  }, [OpportunityRevenueSummaryData]);

  useEffect(() => {
    if (OpportunitySuccessRateData) {
      setPieChartData({
        labels: ["Loss Rate", "Success Rate"],
        series: [
          parseFloat(OpportunitySuccessRateData?.lossRate),
          parseFloat(OpportunitySuccessRateData?.successRate),
          // 100 -
          //   parseFloat(OpportunitySuccessRateData?.lossRate) +
          //   parseFloat(OpportunitySuccessRateData?.successRate),
        ],
      });
    }
  }, [OpportunitySuccessRateData]);

  const callOpportunitySuccessApi = () => {
    if (startDate && endDate)
      dispatch(fetchOpportunitySuccessRateData({ startDate, endDate }));
  };
  console.log(revenueFields);

  return (
    <Box
      sx={{
        marginBottom: "24px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} xl={8}>
          <Box>
            <Box className={classes.chartWhiteContainer}>
              <Box className={classes.chartContainer}>
                <LineChart
                  name="Revenue"
                  fields={revenueFields}
                  data={revenueData}
                />
              </Box>

              <Box className={classes.infoContainer}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "rgb(38 50 56 /1)",
                    letterSpacing: 0,
                    lineHeight: 1.625,
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                >
                  Revenue Summary
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} xl={4}>
          <Box>
            <Box className={classes.chartWhiteContainer}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "20px 10px",
                }}
              >
                <TextField
                  label="Start Date"
                  type="date"
                  size="small"
                  inputProps={{ max: endDate }}
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />

                <TextField
                  label="End Date"
                  type="date"
                  size="small"
                  inputProps={{ min: startDate }}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />

                <Button
                  variant="contained"
                  size="small"
                  disabled={!startDate || !endDate}
                  onClick={callOpportunitySuccessApi}
                >
                  Get
                </Button>
              </Box>

              <Box className={classes.chartContainer}>
                <PieChart data={pieChartData} />
              </Box>

              <Box className={classes.infoContainer}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "rgb(38 50 56 /1)",
                    letterSpacing: 0,
                    lineHeight: 1.625,
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                >
                  Opportunity Success Rate
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default StatCharts;

const useStyles = makeStyles({
  chartWhiteContainer: {
    backgroundColor: "#fff",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    borderRadius: "0.75rem",
    color: "rgb(97 97 97 /1)",
  },

  chartContainer: {
    // margin: "0 16px",
    // borderRadius: "0.75rem",
    // position: "relative",
    // top: "-1.5rem",
    minHeight: "235px",
  },

  infoContainer: {
    padding: "0 24px 24px",
  },
});
