import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

function PieChart({ data }) {
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [],
      colors: ["#FF6347", "#3CB371", "#0000FF"], // Default colors for each pie slice
      dataLabels: {
        style: {
          colors: ["#fff", "#fff", "#0000FF"],
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (data) {
      setState({
        series: data?.series,
        options: {
          ...state.options,
          labels: data?.labels,
          colors: data?.sliceColors || state.options.colors, // Use custom slice colors from data if provided
          dataLabels: {
            ...state.options.dataLabels,
            style: {
              ...state.options.dataLabels.style,
              colors:
                data?.labelColors || state.options.dataLabels.style.colors, // Dynamic label colors
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <div>
      <Chart
        options={state?.options}
        series={state?.series}
        type="pie"
        height="350"
      />
    </div>
  );
}

PieChart.propTypes = {
  data: PropTypes.shape({
    series: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    sliceColors: PropTypes.array, // Optional custom colors for each pie slice
    labelColors: PropTypes.array, // Optional custom colors for labels
  }),
};

export default PieChart;
