import React from "react";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import PropTypes from "prop-types";
import AppDialog from "../common/AppDialog/AppDialog";

function LostDialog({
  open,
  onClose,
  setremarks,
  remarks,
  updateStatus,
  loading,
}) {
  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title="Mark Lost"
        minHeight="auto"
        maxWidth="sm"
      >
        <Box my={2}>
          <TextField
            label="Reason"
            variant="outlined"
            type="text"
            fullWidth
            multiline
            rows={5}
            size="small"
            name="description"
            value={remarks}
            onChange={(e) => setremarks(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "5px",
              pt: 2,
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    flex: 1,
                    backgroundColor: "#d9d9d9 !important",
                    color: "inherit",
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: "#a9a9a9 !important",
                    },
                  }}
                  onClick={() => onClose()}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{ flex: 1, fontWeight: 600 }}
                  onClick={() => updateStatus()}
                >
                  Yes
                </Button>
              </>
            )}
          </Box>
        </Box>
      </AppDialog>
    </>
  );
}

export default LostDialog;
LostDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  updateStatus: PropTypes.func,
  selectedRow: PropTypes.any,
  setremarks: PropTypes.func,
  remarks: PropTypes.any,
  loading: PropTypes.any,
};
